import { Injectable } from '@angular/core';
import { Http ,Response , Headers , RequestOptions } from '@angular/http';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  public api_url: string = environment.apiUrl;

  constructor(private _http: Http) { }

  forgotpassword(data){
    return this._http.post(this.api_url + 'forgotpassword',data).pipe(map((res: Response) => res.json()));
  }

  passwordreset(data){
    return this._http.post(this.api_url + 'passwordreset',data).pipe(map((res: Response) => res.json()));
  }

  editprofile(userId,data){
    var token = localStorage.getItem('token');
    var headers_new = new Headers({ 'Accept': 'application/json','Authorization': token });
    var options = new RequestOptions({ headers: headers_new });
    return this._http.put(this.api_url + 'customer/' + userId ,data ,options).pipe(map((res: Response) => res.json()));
  }

  getEditUserDetail(userId){
    var token = localStorage.getItem('token');
    var headers_new = new Headers({ 'Accept': 'application/json','Authorization': token });
    var options = new RequestOptions({ headers: headers_new });
    return this._http.get(this.api_url + 'customer/'+userId +'/edit',options).pipe(map((res: Response) => res.json()));
  }

  changePassword(data){
    return this._http.post(this.api_url + 'changepassword',data).pipe(map((res: Response) => res.json()));
  }
}
