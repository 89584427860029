import { AbstractControl } from '@angular/forms';

export class CustomValidators{

    static passwordMatchValidator(controls: AbstractControl){
        if(controls.get('password').value !== controls.get('password_confirmation').value){
            //return { mismatch: true}
            controls.get('password_confirmation').setErrors( {mismatch: true} );
        }else {
            return true;
        }
    }
}